<template>
  <v-container>
    <v-card class="pa-0">
      <v-card-title>
        Representative List
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          v-debounce:300ms="getItems"
          hide-details
          class="pa-0"
        ></v-text-field>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        dense
        :headers="headers"
        :items="items.data"
        :server-items-length="items.total"
        :options.sync="pagination"
        :loading="loading"
        item-key="name"
        class="elevation-1"
      >
        <template v-slot:item.is_contact_first="{item}">
          <v-simple-checkbox :ripple="false" v-model="item.is_contact_first" :value="item.is_contact_first" @click="updateScheduleRep(item)"></v-simple-checkbox>
        </template>
        <template v-slot:item.action="{item}">
          <v-btn color="grey darken-2" icon dark @click="confirmDelete(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-text class="pt-5">
          Are you sure to remove
          <b>{{vendor.attendee.full_name}}</b> from <b>{{vendor.vendor.name}}'s</b> representative?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="red darken-4" text @click="deleteItem(vendor)">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>

import { parseParams, getAlphabets } from "../../plugins/helper";
import { mapMutations } from "vuex";
export default {
  name: "RepresentativeList",
  data() {
    return {
      loading: false,
      dialog: false,
      vendor: {
        vendor:{ name: null},
        attendee:{full_name:null}
      },
      search: "",
      items: {
        current_page: 1,
        per_page: 10,
        data: [],
      },
      headers: [
        { text: "Vendor Name", value: "vendor.name",sortable: false },
        { text: "Assigned Attendee", value: "attendee.full_name",sortable: false },
        { text: "Company name", value: "vendor.company_name",sortable: false },
        { text: "Scheduled", value: "is_contact_first", sortable: false },
        { text: "Action", value: "action", sortable: false },
      ],
      pagination: {
        itemsPerPage: 10,
        sortBy: ["created_at"],
        page: 1,
        filters: {
          item_active: true,
          alphabet: "",
        },
      },
      alphabets: getAlphabets(),
    };
  },
  computed: {
    pageData() {
      let paginationData = this.pagination;
      paginationData.search = this.search;
      paginationData.searchOption = "name";
      return paginationData;
    },
    getStyles() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return {
          maxWidth: "95%",
          margin: "0 auto",
        };
      } else {
        return {};
      }
    },
  },
  watch: {
    pagination: {
      handler: function () {
        this.getItems();
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    getFormatedDate(date) {
      return new Date(date).toLocaleDateString();
    },
    getItems() {
      let _self = this;
      this.loading = "secondary";
      let url = parseParams(this.pageData);
      this.$axios.get("/admin/representative/list?" + url)
        .then((response) => {
          _self.items = response.data.data;
          this.loading = false;
        })
        .catch(function () {
          this.loading = false;
        });
    },
    confirmDelete(item) {
      this.dialog = true;
      this.vendor = item;
    },
    deleteItem(item) {
      const index = this.items.data.indexOf(item);
      let _self = this;
      _self.loading = "secondary";
      this.$axios.post("/admin/representative/delete", {
        rep_id: item.rep_id,
      })
        .then((res) => {
          if (res.status) {
            _self.items.data.splice(index, 1);
            _self.dialog = false;
          }
          _self.loading = false;
        })
        .catch(function () {
          _self.loading = false;
        });
    },
    updateScheduleRep(item) {
      this.loading = true;
      this.$axios.post("/admin/representative/schedule",item)
        .then(() => {
        })
        .catch(function () {
          this.loading = false;
        })
        .then(() => {
          this.loading = false;
        })
    }
  },
};
</script>